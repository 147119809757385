import { Text } from '@chakra-ui/react'

export const MiimosaCestQuoi = {
  title: 'MiiMOSA, c’est quoi ?',
  text: (
    <>
      <Text>
        MiiMOSA est la plateforme de financement leader en Europe dédiée à la transition agricole et alimentaire. Après
        avoir accompagné 7 500 projets pour 180 millions d’euros de financement (en France et en Belgique), MiiMOSA a
        lancé en janvier 2022 le premier fonds de dette dédié à la thématique.
        <br />
        <br />
        Labellisée BCorp, l’entreprise s’engage à recréer du lien entre des porteurs de projet et l’ensemble de la
        société, afin que chacun participe à la résolution des enjeux alimentaires, environnementaux, climatiques,
        sanitaires et énergétiques.
      </Text>
    </>
  ),
}

export const FinancementParticipatif = {
  title: 'Qu’est ce que le financement participatif ?',
  text: (
    <>
      <Text>
        Le financement participatif (crowdfunding en anglais) est un mode de financement permettant au grand public de
        soutenir financièrement et collectivement des projets qui le séduisent.
        <br />
        <br />
        MiiMOSA utilise deux offres de financement participatif : le don avec contrepartie et le prêt rémunéré. Le
        financement participatif permet également à des acteurs professionnels (investisseurs, entreprises, etc.) de
        soutenir les projets.
      </Text>
    </>
  ),
}

export const EngagementsDeNestle = {
  title: 'Quels sont les engagements de Nestlé pour l’agriculture en France ?',
  text: (
    <>
      <Text>
        Avec 14 usines en France qui transforment près d’1 million de tonnes de matières premières agricoles chaque
        année, le groupe Nestlé a construit au fil du temps une relation forte et pérenne avec le monde agricole
        français et a une responsabilité au sein de cet écosystème. Alors que l’urgence climatique s’intensifie,
        menaçant la viabilité de notre modèle alimentaire actuel, nous avons
        <span style={{ fontWeight: '700' }}>
          {' '}
          choisi de faire du soutien à la transition agroécologique une priorité{' '}
        </span>
        de notre plan d’action pour les prochaines années. Dans cette course contre la montre climatique, notre objectif
        est d’accompagner des agriculteurs français à transitionner vers des systèmes alimentaires régénérateurs, comme
        l’agriculture régénératrice ou l’agriculture biologique, afin de leur permettre de produire en quantité tout en
        prenant soin de leurs sols et de la biodiversité.
      </Text>
    </>
  ),
}
